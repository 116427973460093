import * as API from '@/services/api';

export async function fetchTeams(repeatedMeasurementId) {
  const response = await API.get(`/api/admin/teams?repeatedMeasurementId=${repeatedMeasurementId}`);
  return response.data;
}

export async function createTeamRequest(team) {
  const response = await API.post('/api/admin/teams', team);
  return response.data;
}

export async function deleteTeamRequest(teamId) {
  const response = await API.del(`/api/teams/${teamId}`);
  return response.data;
}

export async function addTeamMembersRequest(productConfigurationId, teamId, userTokens) {
  const response = await API.post(`/api/productconfig/${productConfigurationId}/teams/${teamId}/members`, {
    userTokens,
  });
  return response.data;
}

export async function deleteTeamMemberRequest(productConfigurationId, teamId, memberToken) {
  const response = await API.del(`/api/productconfig/${productConfigurationId}/teams/${teamId}/members/${memberToken}`);
  return response.data;
}

export async function fetchTeamResults(teamId, repeatedMeasurementId) {
  const response = await API.get(`/api/teams/${teamId}/repeated_measurement/${repeatedMeasurementId}/result`);
  return response.data;
}

export async function fetchTeamComparisonResults(teamIds, repeatedMeasurementId, locale) {
  const teamIdStrings = teamIds.map((id) => `teamId=${id}`);
  const queryString = `?${teamIdStrings.join('&')}&locale=${locale}`;
  const response = await API.get(`/api/teams/compare/${repeatedMeasurementId}${queryString}`);
  return response.data;
}
