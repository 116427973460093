import { START_LOADING, COMPLETE_LOADING } from '@/store';
import i18n from '@/plugins/i18n';
import { LOCALES } from '@/constants/i18n';
import { mapToI18nObject, sanitizeLanguage, sanitizeLocale, updateD3Locale } from '@/utils/i18n';
import { setMomentLocale } from '@/utils/date';

import * as languageService from '@/services/language';
import { getBaseUrl } from '@/utils/interaction';
import isNewTranslation, { state } from './state';
import { CLEAR_AVAILABLE_TRANSLATION, SET_AVAILABLE_TRANSLATION, SET_LOCALE } from './constants';

const actions = {
  setLocale({ commit }, payload) {
    const { locale } = payload;
    const localeObject = LOCALES[sanitizeLocale(locale)];
    commit(SET_LOCALE, localeObject);
    updateD3Locale(localeObject.id);
    setMomentLocale(localeObject.language);
  },

  async fetchTranslations({ commit }, payload) {
    commit(START_LOADING);
    const { textType, forcedLanguage, filter } = payload;
    let { language } = state.locale;

    if (forcedLanguage !== undefined && forcedLanguage !== null) {
      language = sanitizeLanguage(forcedLanguage);
    }

    const translatedTextTypeKey = `${textType}_${language}`;
    if (isNewTranslation(state, translatedTextTypeKey) === undefined) {
      const textTypeTranslation = {
        key: translatedTextTypeKey,
        textType,
        language,
      };
      commit(SET_AVAILABLE_TRANSLATION, textTypeTranslation);
      try {
        const translations = await languageService.fetchTranslations(language, textType, filter);
        const texts = mapToI18nObject(translations);

        // create translation key which holds the base URL of the application,
        // in order to allow other translation keys to dynamically reference this URL.
        // Referencing translations is done by using linked locale messages,
        // e.g.: @:BASE_URL see below link for more information
        //   https://kazupon.github.io/vue-i18n/guide/messages.html#linked-locale-messages
        texts.BASE_URL = getBaseUrl();
        i18n.mergeLocaleMessage(language, texts);
      } catch (e) {
        console.error(e);
      }
    }
    commit(COMPLETE_LOADING);
  },

  async reloadTranslations({ commit, dispatch }) {
    const translationsToReload = state.availableTranslations;
    commit(CLEAR_AVAILABLE_TRANSLATION);
    return Promise.all(
      translationsToReload.map((value) => {
        return dispatch('fetchTranslations', {
          textType: value.textType,
          forcedLanguage: value.language,
        });
      })
    );
  },

  async clearTranslations() {
    i18n.setLocaleMessage('DE', {
      DE: {},
      EN: {},
    });
    i18n.setLocaleMessage('EN', {
      DE: {},
      EN: {},
    });
  },
};

export default actions;
