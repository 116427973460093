import * as API from '@/services/api';

export async function loginRequest(user, redirectUrl) {
  const payload = {
    ...user,
    redirectUrl,
  };
  const response = await API.post('/api/login', payload);
  return response.data;
}

export async function logoutRequest() {
  const response = await API.post('/api/logout');
  return response.data;
}

export async function fetchEnabledComponents() {
  const response = await API.get('/api/system/config');
  return response.data;
}
