import * as API from '@/services/api';

export async function fetchBQPCertificateContent(interactionInstanceToken, locale) {
  const response = await API.get(`/api/report/content/bqp/certificate/${interactionInstanceToken}?locale=${locale}`);
  return response.data;
}

export async function downloadBQPCertificate(token, interactionType, locale, format, anonymize) {
  const response = await API.pdfDownload(
    `/api/render/report/${token}?&interactionType=${interactionType}&locale=${locale}&pageFormat=${format}&anonymize=${anonymize}`
  );
  return response;
}

export async function fetchBQPCertificateVerification(certificateVerificationToken, locale) {
  const response = await API.get(`/api/verification/certificate/${certificateVerificationToken}?locale=${locale}`);
  return response.data;
}

export async function fetchBQPCertificateVerificationLink(interactionInstanceToken) {
  const response = await API.get(`/api/certificate/verificationLink/${interactionInstanceToken}`);
  return response.data;
}
