import * as teamService from '@/services/teams';
import * as interactionService from '@/services/interaction';
import * as weightsService from '@/services/weights';

import { START_LOADING, COMPLETE_LOADING } from '@/store';
import * as types from './constants';

const actions = {
  async addClientCompare({ state, commit }, payload) {
    const { client, user, userNameText } = payload;

    const interactionInstanceToken = client.lastCompleteInteractionToken;

    if (interactionInstanceToken) {
      commit(START_LOADING);

      try {
        const dashboardContent = await interactionService.fetchBQPDashboardContent(interactionInstanceToken, 'EN_US');

        client.dashboardResults = { name: dashboardContent.name, date: dashboardContent.date };
        client.instanceDimensions = dashboardContent.dimensions;
        client.dimensionGraph = dashboardContent.graph;

        if (state.clientCompareList.length > 0) {
          const similarity = await interactionService.fetchInstanceSimilarity(
            state.clientCompareList[0].lastCompleteInteractionToken,
            interactionInstanceToken
          );
          client.similarity = Math.round(similarity);
        }

        // build new compare list by either adding or replacing an existing user
        let updatedCompareList = state.clientCompareList.map((existingClient) =>
          JSON.parse(JSON.stringify(existingClient))
        );
        const clientIndex = updatedCompareList.findIndex((existingClient) => existingClient.token === client.token);

        if (clientIndex === -1) {
          updatedCompareList = [...updatedCompareList, client];
        } else {
          updatedCompareList[clientIndex] = client;
        }

        // build final compare list where users will be anonymized
        let userIndex = 1;
        const finalCompareList = updatedCompareList.map((updateClient) => {
          updateClient.anonymize = false;
          if (user.anonymizationAllowListEnabled) {
            updateClient.anonymize =
              user.anonymizationAllowList.find((visibleUser) => visibleUser.bqUserToken === updateClient.token) ===
              undefined;

            updateClient.anonymizedName = '***** *****';
            if (updateClient.anonymize) {
              updateClient.anonymizedName = `${userNameText} ${userIndex}`;
              updateClient.anonymizedInitials = `${userNameText.trim().slice(0, 1)}${userIndex}`;
              userIndex += 1;
            }
          }

          return updateClient;
        });
        commit(types.ADD_CLIENT_COMPARE, finalCompareList);
        commit(COMPLETE_LOADING);
      } catch (e) {
        console.error(e);
        commit(COMPLETE_LOADING);
      }
    } else {
      throw new Error('No Completed Instance');
    }
  },
  async swapClientCompare({ state, commit }, payload) {
    const { candidate } = payload;
    const compareListCp = [...state.clientCompareList];
    const oldIndex = compareListCp.findIndex((candidateItem) => candidateItem.token === candidate.token);

    compareListCp[oldIndex] = compareListCp[0];
    compareListCp[0] = candidate;

    commit('EMPTY_CLIENT_COMPARE');

    const primaryCandidate = compareListCp[0];

    const updatedCompareList = Promise.all(
      compareListCp.map(async (candidateItem, i) => {
        if (i === 0) {
          delete candidateItem.similarity;
        }
        if (i > 0) {
          const similarity = await interactionService.fetchInstanceSimilarity(
            primaryCandidate.lastCompleteInteractionToken,
            candidateItem.lastCompleteInteractionToken
          );
          candidateItem.similarity = Math.round(similarity);
        }
        return candidateItem;
      })
    );

    updatedCompareList.then((result) => commit(types.ADD_CLIENT_COMPARE, result));
  },
  removeClientCompare({ commit }, payload) {
    const { client } = payload;
    commit(types.REMOVE_CLIENT_COMPARE, client.token);
  },
  async fetchTeams({ commit }, payload) {
    commit(START_LOADING);
    try {
      const { teams } = await teamService.fetchTeams(payload);
      commit(types.FETCH_TEAMS_SUCCESS, teams);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_TEAMS_FAILURE, e);
      commit(COMPLETE_LOADING);
    }
  },
  async createTeam({ commit }, payload) {
    commit(START_LOADING);
    const { team } = payload;
    try {
      const teamId = await teamService.createTeamRequest(team);
      commit(types.CREATE_TEAM_SUCCESS, teamId);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.CREATE_TEAM_FAILURE, e);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },
  async deleteTeam({ commit }, payload) {
    commit(START_LOADING);
    const { teamId } = payload;
    try {
      await teamService.deleteTeamRequest(teamId);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.DELETE_TEAM_FAILURE, e);
      commit(COMPLETE_LOADING);
    }
  },
  async addTeamMembers({ commit }, payload) {
    commit(START_LOADING);
    const { productConfigurationId, teamId, userTokens } = payload;
    try {
      await teamService.addTeamMembersRequest(productConfigurationId, teamId, userTokens);
      commit(types.ADD_TEAM_MEMBER_SUCCESS);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.ADD_TEAM_MEMBER_FAILURE, e);
      commit(COMPLETE_LOADING);
    }
  },
  async deleteTeamMember({ commit }, payload) {
    commit(START_LOADING);
    const { productConfigurationId, teamId, memberToken } = payload;
    try {
      await teamService.deleteTeamMemberRequest(productConfigurationId, teamId, memberToken);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.DEL_TEAM_MEMBER_SUCCESS, e);
      commit(COMPLETE_LOADING);
    }
  },
  async fetchTeamResults({ commit }, payload) {
    commit(START_LOADING);
    const { teamId, repeatedMeasurementId } = payload;
    try {
      const teamResults = await teamService.fetchTeamResults(teamId, repeatedMeasurementId);
      commit(types.FETCH_TEAM_RESULTS_SUCCESS, teamResults);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_TEAM_RESULTS_FAILURE, e);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },
  async fetchTeamComparisonResults({ commit }, payload) {
    commit(START_LOADING);
    const { teamIds, repeatedMeasurementId, locale } = payload;

    try {
      const teamComarisonResults = await teamService.fetchTeamComparisonResults(teamIds, repeatedMeasurementId, locale);
      commit(types.FETCH_TEAM_COMPARISON_SUCCESS, teamComarisonResults);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_TEAM_COMPARISON_FAILURE, e);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },
  async fetchAvailableWeights({ commit }, payload) {
    const { productType } = payload;
    try {
      const availableWeights = await weightsService.fetchAvailableWeights(productType);
      commit(types.FETCH_AVAILABLE_WEIGHTS_SUCCESS);

      return availableWeights;
    } catch (e) {
      commit(types.FETCH_AVAILABLE_WEIGHTS_FAILURE);
      throw e;
    }
  },
  async createWeightProfile({ commit }, payload) {
    const { weightProfile } = payload;

    const r = await weightsService.createWeightProfile(weightProfile);
    commit(types.FETCH_AVAILABLE_WEIGHTS_SUCCESS);

    return r;
  },
  async updateWeightProfile({ commit }, payload) {
    const { weightProfile } = payload;
    try {
      const r = await weightsService.updateWeightProfile(weightProfile);
      commit(types.UPDATE_WEIGHT_PROFILE_SUCCESS);
      return r;
    } catch (e) {
      commit(types.UPDATE_WEIGHT_PROFILE_FAILURE);
      throw e;
    }
  },
  async deleteWeightProfile({ commit }, payload) {
    const { weightProfileId } = payload;

    try {
      await weightsService.deleteWeightProfile(weightProfileId);
      commit(types.DELETE_WEIGHT_PROFILE_SUCCESS);
    } catch (e) {
      commit(types.DELETE_WEIGHT_PROFILE_FAILURE);
      throw e;
    }
  },
  async fetchWeightProfiles({ commit }, payload) {
    const { productType } = payload;
    try {
      const weightProfiles = await weightsService.fetchWeightProfiles(productType);
      commit(types.FETCH_WEIGHT_PROFILES_SUCCESS);

      return weightProfiles;
    } catch (e) {
      commit(types.FETCH_WEIGHT_PROFILES_FAILURE);
      throw e;
    }
  },
  async setWeightProfileForUser(_, payload) {
    const { profileId } = payload;
    await weightsService.setWeightProfileForUser({ profileId });
  },
};

export default actions;
