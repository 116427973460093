export const DOWNLOAD_CERTIFICATE_SUCCESS = 'DOWNLOAD_CERTIFICATE_SUCCESS';
export const DOWNLOAD_CERTIFICATE_FAILURE = 'DOWNLOAD_CERTIFICATE_FAILURE';
export const START_CERTIFICATE_LOADING = 'START_CERTIFICATE_LOADING';
export const COMPLETE_CERTIFICATE_LOADING = 'COMPLETE_CERTIFICATE_LOADING';
export const FETCH_CERTIFICATE_CONTENT_REQUEST = 'FETCH_CERTIFICATE_CONTENT_REQUEST';
export const FETCH_CERTIFICATE_CONTENT_FAILURE = 'FETCH_CERTIFICATE_CONTENT_FAILURE';
export const FETCH_CERTIFICATE_CONTENT_SUCCESS = 'FETCH_CERTIFICATE_CONTENT_SUCCESS';
export const FETCH_CERTIFICATE_VERIFICATION_SUCCESS = 'FETCH_CERTIFICATE_VERIFICATION_SUCCESS';
export const FETCH_CERTIFICATE_VERIFICATION_FAILURE = 'FETCH_CERTIFICATE_VERIFICATION_FAILURE';
export const START_CERTIFICATE_VERIFICATION_LOADING = 'START_CERTIFICATE_VERIFICATION_LOADING';
export const COMPLETE_CERTIFICATE_VERIFICATION_LOADING = 'COMPLETE_CERTIFICATE_VERIFICATION_LOADING';
export const FETCH_CERTIFICATE_VERIFICATION_LINK_SUCCESS = 'FETCH_CERTIFICATE_VERIFICATION_LINK_SUCCESS';
export const FETCH_CERTIFICATE_VERIFICATION_LINK_FAILURE = 'FETCH_CERTIFICATE_VERIFICATION_LINK_FAILURE';
