import * as API from '@/services/api';

export async function sendInteractionInvitationEmail(interactionInstanceToken) {
  const response = await API.get(`/api/instance/${interactionInstanceToken}/invite`);
  return response.data;
}

export async function updateInteractionAutosends(interactionInstanceToken, autosends) {
  const response = await API.patch(`/api/instance/${interactionInstanceToken}/auto_sends`, autosends);
  return response.data;
}
