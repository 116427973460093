import { PRODUCT } from '@/constants/interaction';

export const getProductKey = (name) => {
  switch (name) {
    // below case statements are deprecated, replacement logic is contained within the default block
    case PRODUCT.BQ_ADVISORY_BANK.interactionSlug:
      return PRODUCT.BQ_ADVISORY_BANK.translationKey;
    case PRODUCT.BQ_ADVISORY_WEALTH.interactionSlug:
      return PRODUCT.BQ_ADVISORY_WEALTH.translationKey;
    case PRODUCT.BQ_RISK.interactionSlug:
      return PRODUCT.BQ_RISK.translationKey;
    case PRODUCT.BQ_SELECTION.interactionSlug:
      return PRODUCT.BQ_SELECTION.translationKey;
    case PRODUCT.BQ_PERFORMANCE.interactionSlug:
      return PRODUCT.BQ_PERFORMANCE.translationKey;
    default: {
      if (name !== undefined && name !== null) {
        // should be primary way of getting additional product information
        const product = PRODUCT[name.toUpperCase()];

        if (product !== undefined) {
          return product.translationKey;
        }
      }
      return '';
    }
  }
};

export const getBaseUrl = () => {
  // TODO: set url via .env/consts
  if (process.env.NODE_ENV === 'development') {
    return `http://${window.location.hostname}:8080/`;
  }

  return `https://${window.location.hostname}/`;
};

export const getInteractionBaseUrl = () => {
  return `${getBaseUrl()}i/`;
};

export const generateMailtoEmail = (email, subject, body) => {
  const emailSubject = encodeURIComponent(subject);
  // replace <br> for \n for proper newline encoding
  // NOTE: usual \n in translations won't work with vue-118n and encodeURIComponent,
  // newline needs to be replaced/inserted in code
  const emailBodyReplaced = body.replace(/<br>/g, '\n');
  const emailBody = encodeURIComponent(emailBodyReplaced);

  window.location.href = `mailto:${email}?subject=${emailSubject}&body=${emailBody}`;
};
