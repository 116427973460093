import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import i18n, { BQTranslationPlugin } from '@/plugins/i18n';
import VueSimpleSVG from 'vue-simple-svg';
import vSelect from 'vue-select';

import '@/styles/custom.scss';
import 'vue-select/dist/vue-select.css';
import Clipboard from 'v-clipboard';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import globalMixin from '@/mixins/globalMixin';
import { BQUserPermissionPlugin } from '@/plugins/userPermissions';
import store from './store';
import router from './router';
import App from './App.vue';
import { BQEnvironmentContextPlugin } from '@/plugins/environmentContext';

import VueSvgInlinePlugin from 'vue-svg-inline-plugin';
import Hotjar from '@hotjar/browser';

Vue.use(VueSvgInlinePlugin);

Vue.config.productionTip = false;

Vue.component('VSelect', vSelect);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSimpleSVG);
Vue.use(Clipboard);
Vue.use(BQTranslationPlugin);
Vue.use(BQUserPermissionPlugin);
Vue.use(BQEnvironmentContextPlugin);

Vue.mixin(globalMixin);

// GLOBAL_ENV_CONFIG is set via kubernetes configMaps, see in /kubernetes deployment yaml files
// variable is not configured at build time, that's why eslint needs to be disabled here temporarily
/* eslint-disable */
let hotjarSiteId = null;
if (typeof GLOBAL_ENV_CONFIG === 'undefined') {
  console.warn('GLOBAL_ENV_CONFIG not defined.');
} else {
  hotjarSiteId = (GLOBAL_ENV_CONFIG && GLOBAL_ENV_CONFIG.HOTJAR_ID) || null;
}
/* eslint-enable */
const hotjarVersion = 6;

Hotjar.init(hotjarSiteId, hotjarVersion);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
