import * as API from '@/services/api';

export async function fetchAvailableWeights(productType) {
  const response = await API.get(`/api/product/${productType}/weights`);
  return response.data;
}

export async function fetchWeightProfiles(productType) {
  const response = await API.get(`/api/product/${productType}/weightProfiles`);
  return response.data;
}

export async function createWeightProfile(weightProfile) {
  const response = await API.post('/api/weight/profile', weightProfile);
  return response.data;
}

export async function updateWeightProfile(weightProfile) {
  const response = await API.patch(`/api/weight/profile/${weightProfile.id}`, weightProfile);
  return response.data;
}

export async function deleteWeightProfile(weightProfileId) {
  const response = await API.del(`/api/weight/profile/${weightProfileId}`);
  return response.data;
}

export async function setWeightProfileForUser(weightProfileId) {
  const response = await API.post('/api/user/weightProfile', weightProfileId);
  return response.data;
}
