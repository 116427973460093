export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SOCIALS_AUTH_RESPONSE = 'SOCIALS_AUTH_RESPONSE';
export const SOCIALS_AUTH_LOADING_START = 'SOCIALS_AUTH_LOADING_START';
export const SOCIALS_AUTH_LOADING_COMPLETE = 'SOCIALS_AUTH_LOADING_COMPLETE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const SET_USER = 'SET_USER';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const START_CLIENT_LOADING = 'START_CLIENT_LOADING';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const SET_API_CREDENTIALS = 'SET_API_CREDENTIALS';
export const FETCH_CREATE_USER_LIMITS_SUCCESS = 'FETCH_CREATE_USER_LIMITS_SUCCESS';
export const FETCH_CREATE_USER_LIMITS_FAILURE = 'FETCH_CREATE_USER_LIMITS_FAILURE';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
export const SET_CLIENTS_INSTANCES = 'SET_CLIENTS_INSTANCES';
export const FETCH_PRIVACY_POLICY_DATA_SUCCESS = 'FETCH_PRIVACY_POLICY_DATA_SUCCESS';
export const FETCH_PRIVACY_POLICY_DATA_FAILURE = 'FETCH_PRIVACY_POLICY_DATA_FAILURE';
