import * as selfService from '@/services/selfService';
import { COMPLETE_LOADING, START_LOADING } from '@/store';
import * as types from './constants';

const actions = {
  async fetchAvailablePaymentConfigurations({ commit }, payload) {
    commit(START_LOADING);

    const { productType } = payload;

    try {
      const paymentConfigurations = await selfService.fetchAvailablePaymentConfigurations(productType);
      commit(types.FETCH_PAYMENT_CONFIGURATIONS_SUCCESS, paymentConfigurations);
    } catch (e) {
      commit(types.FETCH_PAYMENT_CONFIGURATIONS_FAILURE, e);
    } finally {
      commit(COMPLETE_LOADING);
    }
  },
  async unlockInteractionWithCredit({ commit }, payload) {
    const { interactionInstanceToken } = payload;

    try {
      const unlockResponse = await selfService.unlockInteractionWithCredit(interactionInstanceToken);
      commit(types.UNLOCK_IX_WITH_CREDIT_SUCCESS, unlockResponse);
    } catch (e) {
      commit(types.UNLOCK_IX_WITH_CREDIT_SUCCESS);
    }
  },

  async startBQATrial() {
    await selfService.startBQATrial();
  },

  async dismissBQATrial() {
    await selfService.dismissBQATrial();
  },

  async resendConfirmationEmail() {
    return selfService.resendConfirmationEmail();
  },

  async addAdditionalProduct(_, payload) {
    const { initiatorId, productType } = payload;

    return selfService.addAdditionalProduct(initiatorId, productType);
  },
};

export default actions;
