import { state, mutations } from './state';
import actions from './actions';
import getters from './getters';

export const interactionModule = {
  state,
  mutations,
  actions,
  getters,
};

export default interactionModule;
