export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_FAILURE = 'DOWNLOAD_REPORT_FAILURE';
export const START_REPORT_LOADING = 'START_REPORT_LOADING';
export const COMPLETE_REPORT_LOADING = 'COMPLETE_REPORT_LOADING';
export const FETCH_REPORT_DOWNLOAD_INFO_REQUEST = 'FETCH_REPORT_DOWNLOAD_INFO_REQUEST';
export const FETCH_REPORT_DOWNLOAD_INFO_FAILURE = 'FETCH_REPORT_DOWNLOAD_INFO_FAILURE';
export const FETCH_REPORT_DOWNLOAD_INFO_SUCCESS = 'FETCH_REPORT_DOWNLOAD_INFO_SUCCESS';
export const FETCH_BQP_REPORT_CONTENT_REQUEST = 'FETCH_BQP_REPORT_CONTENT_REQUEST';
export const FETCH_BQP_REPORT_CONTENT_SUCCESS = 'FETCH_BQP_REPORT_CONTENT_SUCCESS';
export const FETCH_BQP_REPORT_CONTENT_FAILURE = 'FETCH_BQP_REPORT_CONTENT_FAILURE';
export const FETCH_BQA_REPORT_CONTENT_REQUEST = 'FETCH_BQA_REPORT_CONTENT_REQUEST';
export const FETCH_BQA_REPORT_CONTENT_SUCCESS = 'FETCH_BQA_REPORT_CONTENT_SUCCESS';
export const FETCH_BQA_REPORT_CONTENT_FAILURE = 'FETCH_BQA_REPORT_CONTENT_FAILURE';
export const FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_REQUEST = 'FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_REQUEST';
export const FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_FAILURE = 'FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_FAILURE';
export const FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_SUCCESS = 'FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_SUCCESS';
export const DOWNLOAD_RECOMMENDATIONS_REPORT_SUCCESS = 'DOWNLOAD_RECOMMENDATIONS_REPORT_SUCCESS';
export const DOWNLOAD_RECOMMENDATIONS_REPORT_FAILURE = 'DOWNLOAD_RECOMMENDATIONS_REPORT_FAILURE';
export const START_RECOMMENDATIONS_REPORT_LOADING = 'START_RECOMMENDATIONS_REPORT_LOADING';
export const COMPLETE_RECOMMENDATIONS_REPORT_LOADING = 'COMPLETE_RECOMMENDATIONS_REPORT_LOADING';
