export const state = {
  biCategories: null,
  selectedBiCategoryDetails: null,
  biCategoryDetailsLoading: false,
  biClientsList: null,
  biClientsListLoading: false,
  clientProfile: null,
  selectedBiCategory: null,
  selectedClientGroup: null,
};

export const mutations = {
  FETCH_BI_CATEGORIES_SUCCESS(state, payload) {
    state.biCategories = payload;
  },
  FETCH_BI_CATEGORIES_FAILURE() {},
  FETCH_BI_CATEGORY_DETAILS_SUCCESS(state, payload) {
    state.selectedBiCategoryDetails = payload;
  },
  FETCH_BI_CATEGORY_DETAILS_FAILURE() {},
  FETCH_BI_CLIENTS_LIST_SUCCESS(state, payload) {
    state.biClientsList = payload;
  },
  FETCH_BI_CLIENTS_LIST_FAILURE() {},
  FETCH_CLIENT_PROFILE_SUCCESS(state, payload) {
    state.clientProfile = payload;
  },
  FETCH_CLIENT_PROFILE_FAILURE() {},
  RESET_CLIENT_PROFILE() {
    state.clientProfile = null;
  },
  SET_BI_CATEGORY(state, payload) {
    state.selectedBiCategory = payload;
  },
  START_LOADING_CATEGORY(state) {
    state.biCategoryDetailsLoading = true;
  },
  COMPLETE_LOADING_CATEGORY(state) {
    state.biCategoryDetailsLoading = false;
  },
  START_LOADING_BI_LIST(state) {
    state.biClientsListLoading = true;
  },
  COMPLETE_LOADING_BI_LIST(state) {
    state.biClientsListLoading = false;
  },
  SET_CLIENT_GROUP(state, payload) {
    state.selectedClientGroup = payload;
  },
};
