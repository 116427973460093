export const TEXT_TYPES = {
  ITEM: 'ITEM',
  CHOICE: 'CHOICE',
  INTERACTION_BASE: 'INTERACTION_BASE',
  DASHBOARD: 'DASHBOARD',
  BQUI: 'BQUI',
  EMAIL: 'EMAIL',
  ERROR: 'ERROR',
  REPORT: 'REPORT',
  BI: 'BI',
};

export const LOCALES = {
  DE_DE: {
    id: 'DE_DE',
    tag: 'DE-de',
    language: 'DE',
    currency: '€',
    thousands: '.',
    dateFormat: 'DD.MM.YYYY',
    dateFormatLong: 'D. MMMM YYYY',
    d3DateFormatShort: '%d %b %Y',
    fileNameDateFormat: 'DD-MM-YYYY',
    text: 'Deutsch',
  },
  EN_UK: {
    id: 'EN_UK',
    tag: 'EN-uk',
    language: 'EN',
    currency: '€',
    thousands: ',',
    dateFormat: 'DD/MM/YYYY',
    dateFormatLong: 'MMMM D, YYYY',
    d3DateFormatShort: '%d %b %Y',
    fileNameDateFormat: 'DD-MM-YYYY',
    text: 'English UK',
  },
  EN_US: {
    id: 'EN_US',
    tag: 'EN-us',
    language: 'EN',
    currency: '$',
    thousands: ',',
    dateFormat: 'MM/DD/YYYY',
    dateFormatLong: 'MMMM D, YYYY',
    d3DateFormatShort: '%b %d %Y',
    fileNameDateFormat: 'MM-DD-YYYY',
    text: 'English US',
  },
};
