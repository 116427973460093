export const PERMISSION_REALM = {
  BQ_ADVISORY_BANK: 'BQ_ADVISORY_BANK',
  BQ_ADVISORY_WEALTH: 'BQ_ADVISORY_WEALTH',
  BQ_PERFORMANCE_SELF_ASSESSMENT: 'BQ_PERFORMANCE_SELF_ASSESSMENT',
  BQ_RISK: 'BQ_RISK',
  BQ_SELECTION: 'BQ_SELECTION',
  BQ_ADMIN: 'BQ_ADMIN',
  ALL: 'ALL',
};

export const PERMISSION_RESOURCE = {
  TEAMS: 'TEAMS',
  TEAMS_COMPARISON: 'TEAMS_COMPARISON',
  INDIVIDUAL_RESULTS: 'INDIVIDUAL_RESULTS',
  INDIVIDUAL_COMPARISON: 'INDIVIDUAL_COMPARISON',
  INDIVIDUAL_EXPORT: 'INDIVIDUAL_EXPORT',
  REPORT: 'REPORT',
  INTERACTION_INSTANCE: 'INTERACTION_INSTANCE',
  BQ_USER: 'BQ_USER',
  ANONYMIZATION_ALLOW_LIST: 'ANONYMIZATION_ALLOW_LIST',
  PREDICTION_INVESTMENT_STYLE: 'PREDICTION_INVESTMENT_STYLE',
  WEIGHT_MANAGEMENT: 'WEIGHT_MANAGEMENT',
  PRIVACY_POLICY_DATA: 'PRIVACY_POLICY_DATA',
  ALL: 'ALL',
  BQA_DASHBOARD_RISK: 'BQA_DASHBOARD_RISK',
  CERTIFICATE: 'CERTIFICATE',
  API_MANAGEMENT: 'API_MANAGEMENT',
};

export const PERMISSION_OPERATION = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  ALL: 'ALL',
};
