import axios from 'axios';
import router from '@/router';

axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    // Navigate to Login page in case backend responds with 401 Unauthorized
    if (error.response.status === 401 && router.currentRoute.name !== 'Login') {
      // window.location.href = error.response.data
      const redirectUrl = window.location.href;
      router.push({
        name: 'Login',
        params: {
          redirectUrl,
        },
      });
    }
    return Promise.reject(error);
  }
);

const request = (method, url, data, rheaders, responseType) => {
  const headers = rheaders || {
    Accept: 'application/json',
    Source: 'ajax', // as default we set 'ajax' as source to obtain proper status codes instead of redirects from backend
  };

  const config = {
    headers,
    method,
    url,
  };

  if (responseType) config.responseType = responseType;
  if (data) config.data = data;

  return axios(config);
};

export function get(route) {
  return request('GET', route);
}

export function post(route, data) {
  return request('POST', route, data);
}

export function del(route) {
  return request('DELETE', route);
}

export function patch(route, data) {
  return request('PATCH', route, data);
}

export function pdfDownload(route, data) {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/pdf',
  };
  const responseType = 'arraybuffer';

  return request('GET', route, data, headers, responseType);
}
