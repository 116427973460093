export const state = {
  locale: null,
  availableTranslations: [],
};

export default function isNewTranslation(state, translationKey) {
  return state.availableTranslations.find((translation) => translation.key === translationKey);
}

export const mutations = {
  SET_LOCALE(state, payload) {
    state.locale = payload;
  },

  SET_AVAILABLE_TRANSLATION(state, payload) {
    if (isNewTranslation(state, payload) === undefined) {
      state.availableTranslations.push(payload);
    }
  },

  CLEAR_AVAILABLE_TRANSLATION(state) {
    state.availableTranslations = [];
  },
};
