export default {
  biCategories: (state) => state.biCategories,
  selectedBiCategory: (state) => state.selectedBiCategory,
  selectedBiCategoryDetails: (state) => state.selectedBiCategoryDetails,
  biCategoryDetailsLoading: (state) => state.biCategoryDetailsLoading,
  biClientsList: (state) => state.biClientsList,
  biClientsListLoading: (state) => state.biClientsListLoading,
  clientProfile: (state) => state.clientProfile,
  selectedClientGroup: (state) => state.selectedClientGroup,
};
