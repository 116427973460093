export const FETCH_INTERACTIONS_FAILURE = 'FETCH_INTERACTIONS_FAILURE';
export const FETCH_INTERACTIONS_SUCCESS = 'FETCH_INTERACTIONS_SUCCESS';
export const FETCH_USER_INTERACTIONS_SUCCESS = 'FETCH_USER_INTERACTIONS_SUCCESS';
export const FETCH_INTERACTION_LIMITS_FAILURE = 'FETCH_INTERACTION_LIMITS_FAILURE';
export const FETCH_INTERACTION_LIMITS_SUCCESS = 'FETCH_INTERACTION_LIMITS_SUCCESS';
export const FETCH_INSTANCE_DASHBOARD_RESULTS_REQUEST = 'FETCH_INSTANCE_DASHBOARD_RESULTS_REQUEST';
export const FETCH_INSTANCE_DASHBOARD_RESULTS_FAILURE = 'FETCH_INSTANCE_DASHBOARD_RESULTS_FAILURE';
export const FETCH_INSTANCE_DASHBOARD_RESULTS_SUCCESS = 'FETCH_INSTANCE_DASHBOARD_RESULTS_SUCCESS';
export const FETCH_INSTANCE_DIM_RESULTS_REQUEST = 'FETCH_INSTANCE_DIM_RESULTS_REQUEST';
export const FETCH_INSTANCE_DIM_RESULTS_FAILURE = 'FETCH_INSTANCE_DIM_RESULTS_FAILURE';
export const FETCH_INSTANCE_DIM_RESULTS_SUCCESS = 'FETCH_INSTANCE_DIM_RESULTS_SUCCESS';
export const FETCH_INSTANCE_GRAPH_REQUEST = 'FETCH_INSTANCE_GRAPH_REQUEST';
export const FETCH_INSTANCE_GRAPH_FAILURE = 'FETCH_INSTANCE_GRAPH_FAILURE';
export const FETCH_INSTANCE_GRAPH_SUCCESS = 'FETCH_INSTANCE_GRAPH_SUCCESS';
export const FETCH_PRODUCT_INSTANCES_FAILURE = 'FETCH_PRODUCT_INSTANCES_FAILURE';
export const FETCH_PRODUCT_INSTANCES_SUCCESS = 'FETCH_PRODUCT_INSTANCES_SUCCESS';
export const CREATE_INSTANCE_FAILURE = 'CREATE_INSTANCE_FAILURE';
export const CREATE_INSTANCE_SUCCESS = 'CREATE_INSTANCE_SUCCESS';
export const DELETE_INSTANCE_FAILURE = 'DELETE_INSTANCE_FAILURE';
export const DELETE_INSTANCE_SUCCESS = 'DELETE_INSTANCE_SUCCESS';
export const SET_SELECTED_INSTANCE = 'SET_SELECTED_INSTANCE';
export const FETCH_INSTANCE_RISK_DASHBOARD_RESULTS_REQUEST = 'FETCH_INSTANCE_RISK_DASHBOARD_RESULTS_REQUEST';
export const FETCH_INSTANCE_RISK_DASHBOARD_RESULTS_SUCCESS = 'FETCH_INSTANCE_RISK_DASHBOARD_RESULTS_SUCCESS';
export const FETCH_INSTANCE_RISK_DASHBOARD_RESULTS_FAILURE = 'FETCH_INSTANCE_RISK_DASHBOARD_RESULTS_FAILURE';

export const FETCH_BQA_DASHBOARD_CONTENT_REQUEST = 'FETCH_BQA_DASHBOARD_CONTENT_REQUEST';
export const FETCH_BQA_DASHBOARD_CONTENT_SUCCESS = 'FETCH_BQA_DASHBOARD_CONTENT_SUCCESS';
export const FETCH_BQA_DASHBOARD_CONTENT_FAILURE = 'FETCH_BQA_DASHBOARD_CONTENT_FAILURE';

export const FETCH_BQP_DASHBOARD_CONTENT_REQUEST = 'FETCH_BQP_DASHBOARD_CONTENT_REQUEST';
export const FETCH_BQP_DASHBOARD_CONTENT_SUCCESS = 'FETCH_BQP_DASHBOARD_CONTENT_SUCCESS';
export const FETCH_BQP_DASHBOARD_CONTENT_FAILURE = 'FETCH_BQP_DASHBOARD_CONTENT_FAILURE';
export const FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_REQUEST = 'FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_REQUEST';
export const FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_SUCCESS = 'FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_SUCCESS';
export const FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_FAILURE = 'FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_FAILURE';
