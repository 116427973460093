import { formatDateTime } from '@/utils/date';

export const downloadFile = (response, defaultFileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;

  const currentDateTime = formatDateTime(new Date(), 'Y-m-d hh:mm:ss');
  let fileName = `${defaultFileName}_${currentDateTime}.csv`;

  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch.length === 2) {
      const [, returnedFileName] = fileNameMatch;
      fileName = returnedFileName;
    }
  }

  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const arrayBufferToStr = (buf) => String.fromCharCode.apply(null, new Uint8Array(buf));

export const download = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
