import * as API from '@/services/api';

export async function userRequest() {
  const response = await API.get('/api/user');
  return response.data;
}

export async function createClientRequest(client, productConfigurationId) {
  const response = await API.post('/api/u', {
    ...client,
    productConfigurationId,
  });
  return response.data;
}

export async function deleteClientRequest(clientToken, productType) {
  const response = await API.post(`/api/admin/bq_user/${clientToken}/deactivate`, {
    productType,
  });
  return response.data;
}

export async function changePassword(userId, currentPassword, password) {
  const response = await API.patch(`/api/user/${userId}/password`, {
    currentPassword,
    password,
  });
  return response;
}

export async function updateClient(client) {
  const response = await API.patch('/api/admin/bq_user', client);
  return response;
}

export async function updateClientSettings(clientSettings) {
  const response = await API.post('/api/admin/user/settings', clientSettings);
  return response;
}

export async function recoverPassword(passwordResetRequest) {
  return API.post('/api/user/password/reset', passwordResetRequest);
}

export async function resetPassword(passwordResetRequest) {
  return API.post('/api/user/password', passwordResetRequest);
}

export async function verifyPasswordResetToken(verifyToken) {
  return API.get(`/api/user/password/reset/verify?token=${verifyToken}`);
}

export async function addUserToAnonymizationAllowList(productConfigurationId, bqUserToken) {
  return API.post(`/api/productconfig/${productConfigurationId}/user/anonymization_allow_list`, {
    userToken: bqUserToken,
  });
}

export async function removeUserFromAnonymizationAllowList(productConfigurationId, bqUserToken) {
  return API.del(`/api/productconfig/${productConfigurationId}/anonymization_initiator/participant/${bqUserToken}`);
}

export async function verifyEmailToken(emailVerificationToken) {
  return API.get(`/api/email/verification/${emailVerificationToken}`);
}

export async function submitEmail(email) {
  return API.post('/api/presignup/bqaself', email);
}

export async function register(registrationData) {
  return API.post('/api/signup', registrationData);
}

export async function submitEmailBQPSelfAssessment(email) {
  return API.post('/api/presignup/selfassessment', email);
}

export async function registerBQPSelfAssessment(registrationData) {
  return API.post('/api/signup/selfassessment', registrationData);
}

export async function authenticateWithGoogle(productType, payload) {
  return API.post(`/api/oauth/google${productType ? `?productType=${productType}` : ''} `, payload);
}

export async function fetchPrivacyPolicyData(accountId) {
  const response = await API.get(`/api/account/${accountId}/privacy_policy`);
  return response.data;
}

export async function updatePrivacyPolicyData(payload) {
  const response = await API.post('/api/account/privacy_policy', payload);
  return response.data;
}

export async function fetchAPIKey() {
  const response = await API.get('/api/management/credentials');
  return response.data;
}

export async function createAPIKey(description) {
  const response = await API.post('/api/management/credentials', { description });
  return response.data;
}

export async function revokeAPIKey(apiKeyId) {
  const response = await API.del(`/api/management/credentials/${apiKeyId}`);
  return response.data;
}
