import Vue from 'vue';
import Vuex from 'vuex';
import i18n from './i18n';
import user from './user';
import clients from './clients';
import interaction from './interaction';
import report from './report';
import features from './features';
import selfService from './selfService';
import demo from './demo';
import certificate from './certificate';

import { fetchEnabledComponents } from '@/services/authorization';

Vue.use(Vuex);

const SHOW_MODAL = 'SHOW_MODAL';
const CLEAR_MODAL = 'CLEAR_MODAL';
const SHOW_TOAST = 'SHOW_TOAST';
const CLEAR_TOAST = 'CLEAR_TOAST';
const SET_BREADCRUMB = 'SET_BREADCRUMB';
const SET_BREADCRUMB_CONTEXT = 'SET_BREADCRUMB_CONTEXT';
const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
const FETCH_ENABLED_COMPONENTS_SUCCESS = 'FETCH_ENABLED_COMPONENTS_SUCCESS';
const FETCH_ENABLED_COMPONENTS_FAILURE = 'FETCH_ENABLED_COMPONENTS_FAILURE';
export const START_LOADING = 'START_LOADING';
export const COMPLETE_LOADING = 'COMPLETE_LOADING';

export default new Vuex.Store({
  state: {
    app: {
      loading: 0,
      message: {
        type: null,
        message: '',
      },
      toast: null,
      breadcrumbItems: [],
      breadcrumbContext: null,
      modal: null,
    },
    enabledComponents: null,
  },
  mutations: {
    START_LOADING(state) {
      state.app.loading = state.app.loading + 1;
    },
    COMPLETE_LOADING(state) {
      state.app.loading = state.app.loading - 1;
    },
    SHOW_MODAL(state, payload) {
      state.app.modal = payload;
    },
    CLEAR_MODAL(state) {
      state.app.modal = null;
    },
    SHOW_TOAST(state, payload) {
      state.app.toast = payload;
    },
    CLEAR_TOAST(state) {
      state.app.toast = null;
    },
    SET_BREADCRUMB(state, payload) {
      state.app.breadcrumbItems = payload;
    },
    SET_BREADCRUMB_CONTEXT(state, payload) {
      state.app.breadcrumbContext = {
        ...state.app.breadcrumbContext,
        ...payload,
      };
    },
    ADD_BREADCRUMB(state, payload) {
      state.app.breadcrumbItems = [...state.app.breadcrumbItems, payload];
    },
    FETCH_ENABLED_COMPONENTS_SUCCESS(state, payload) {
      state.enabledComponents = payload;
    },
    FETCH_ENABLED_COMPONENTS_FAILURE(_, e) {
      console.error(e);
    },
  },
  getters: {
    appLoading: (state) => state.app.loading > 0,
    modal: (state) => state.app.modal,
    toast: (state) => state.app.toast,
    breadcrumbItems: (state) => state.app.breadcrumbItems,
    breadcrumbContext: (state) => state.app.breadcrumbContext,
    enabledComponents: (state) => state.enabledComponents,
  },
  actions: {
    showModal({ commit }, payload) {
      commit(SHOW_MODAL, payload);
    },
    clearModal({ commit }) {
      commit(CLEAR_MODAL);
    },
    showToast({ commit }, payload) {
      commit(SHOW_TOAST, payload);
    },
    clearToast({ commit }) {
      commit(CLEAR_TOAST);
    },
    setBreadcrumb({ commit }, payload) {
      commit(SET_BREADCRUMB, payload);
    },
    addBreadcrumb({ commit }, payload) {
      commit(ADD_BREADCRUMB, payload);
    },
    setBreadcrumbContext({ commit }, payload) {
      commit(SET_BREADCRUMB_CONTEXT, payload);
    },
    async fetchSystemEnabledComponents({ commit }) {
      try {
        const enabledComponents = await fetchEnabledComponents();
        commit(FETCH_ENABLED_COMPONENTS_SUCCESS, enabledComponents);
      } catch (e) {
        commit(FETCH_ENABLED_COMPONENTS_FAILURE, e);
      }
    },
  },
  modules: {
    i18n,
    user,
    clients,
    interaction,
    report,
    features,
    selfService,
    demo,
    certificate,
  },
});
