<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GlobalMixin',
  computed: {
    ...mapGetters([
      'locale',
      'currentProduct',
      'currentProductConfiguration',
      'currentProductTranslationKey',
      'isSelfService',
    ]),
  },
  methods: {
    ...mapActions(['showToast']),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    /**
     * shows success toast
     * @param message message which should be displayed to the user
     */
    showErrorToast(message) {
      const toast = {
        title: this.$BQUI.COMMON.TOAST_TITLE_ERROR(),
        message,
        variant: 'danger',
      };

      this.showToast(toast);
    },

    /**
     * curried error handler for API errors, which will try to translate error responses returned by
     * BQ APIs, with a human readable text. In case there is no translation present, it will default to
     * the error message which is passed to the error handler.
     *
     * Usage with promises:
     *
     *   axios.post(`/api/request`, dummyRequest).then((response) => {...}) // success case
     *        .catch(handleWithErrorToast('default error message'))         // error case
     *
     * Usage with try { ... } catch:
     *
     *   try {
     *     await axios.post(`/api/request`, dummyRequest);
     *     ...
     *   } catch (e) {
     *     handleWithErrorToast('default error message')(e) // pass in the error to the underlying error handler
     *   }
     *
     * @param message default message which should be displayed to the user, if no API specific error message can be
     *        found
     */
    handleWithErrorToast(message) {
      return (error) => {
        const maybeTranslation = this.translateAPIError(error);
        if (maybeTranslation !== null) {
          this.showErrorToast(maybeTranslation);
        } else {
          this.showErrorToast(message);
        }
      };
    },

    /**
     * tries to translation an API error with format
     *
     * {
     *   errorKey: String
     *   context: Map[String, String]
     * }
     *
     * @param error the error for which a translation is requested
     * @returns {VueI18n.TranslateResult|null} translation string, or null if no translation was found
     */
    translateAPIError(error) {
      if (error.response) {
        const errorResponse = error.response.data;
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx and is not classified as general error
        if (
          errorResponse.errorKey !== undefined &&
          errorResponse.errorKey !== null &&
          !('general.error'.localeCompare(errorResponse.errorKey) === 0)
        ) {
          // following code will format the  error key returned from backend to the current format for error messages ERROR_<textkey>
          // generally backend errors are defined with dot notation and in lowercase
          let sanitizedErrorKey = errorResponse.errorKey.toUpperCase().replaceAll('.', '_');

          // ensure that the error key starts with "ERROR" so it is mapped to the correct translation type
          if (!sanitizedErrorKey.startsWith('ERROR')) {
            sanitizedErrorKey = `ERROR_${sanitizedErrorKey}`;
          }

          // try to find a translation for this error key and return the translation
          const translation = this.$t(sanitizedErrorKey);
          if (translation !== null && translation !== sanitizedErrorKey) {
            return translation;
          }
        }
      }

      // no translation found
      return null;
    },

    /**
     * shows error toast
     * @param message message which should be displayed to the user
     */
    showSuccessToast(message) {
      const toast = {
        title: this.$BQUI.COMMON.TOAST_TITLE_SUCCESS(),
        message,
        variant: 'success',
      };

      this.showToast(toast);
    },

    /**
     * curried error handler for success messages
     *
     * Usage with promises:
     *
     *   axios.post(`/api/request`, dummyRequest).then(handleWithSuccessToast('success message'))
     *
     * @param message message which should be displayed to the user
     */
    handleWithSuccessToast(message) {
      return () => {
        this.showSuccessToast(message);
      };
    },
    redirectUnauthorized() {
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
