export const state = {
  paymentConfigurations: null,
};

export const mutations = {
  FETCH_PAYMENT_CONFIGURATIONS_SUCCESS(state, payload) {
    state.paymentConfigurations = payload;
  },
  FETCH_PAYMENT_CONFIGURATIONS_FAILURE() {},
  UNLOCK_IX_WITH_CREDIT_SUCCESS() {},
  UNLOCK_IX_WITH_CREDIT_FAILURE() {},
};
