import { PERMISSION_OPERATION, PERMISSION_REALM, PERMISSION_RESOURCE } from '@/constants/userPermissions';

export const validatePermission = function (
  availableUserPermissions,
  requiredRealm,
  requiredResource,
  requiredOperation
) {
  if (requiredRealm && requiredResource && requiredOperation) {
    const foundPermission = availableUserPermissions.find((permission) => {
      return (
        (permission.realm === requiredRealm.toUpperCase() || permission.realm === PERMISSION_REALM.ALL) &&
        (permission.resource === requiredResource.toUpperCase() || permission.resource === PERMISSION_RESOURCE.ALL) &&
        (permission.operation === requiredOperation.toUpperCase() || permission.operation === PERMISSION_OPERATION.ALL)
      );
    });

    return !!foundPermission;
  }
  return false;
};
