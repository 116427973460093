import * as reportService from '@/services/report';
import * as types from './constants';

const actions = {
  async fetchBQPReportContent({ commit }, payload) {
    const { interactionInstanceToken } = payload;

    commit(types.FETCH_BQP_REPORT_CONTENT_REQUEST);
    try {
      const reportContent = await reportService.fetchBQPReportContent(interactionInstanceToken);
      commit(types.FETCH_BQP_REPORT_CONTENT_SUCCESS, reportContent);
    } catch (e) {
      commit(types.FETCH_BQP_REPORT_CONTENT_FAILURE, e);
      throw e;
    }
  },

  async fetchBQAReportContent({ commit }, payload) {
    const { interactionInstanceToken, locale } = payload;

    commit(types.FETCH_BQA_REPORT_CONTENT_REQUEST);
    try {
      const reportContent = await reportService.fetchBQAReportContent(interactionInstanceToken, locale);
      commit(types.FETCH_BQA_REPORT_CONTENT_SUCCESS, reportContent);
    } catch (e) {
      commit(types.FETCH_BQA_REPORT_CONTENT_FAILURE, e);
      throw e;
    }
  },

  async fetchBQARecommendationsReportContent({ commit }, payload) {
    const { interactionInstanceToken, locale } = payload;

    commit(types.FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_REQUEST);
    try {
      const reportContent = await reportService.fetchBQARecommendationsReportContent(interactionInstanceToken, locale);
      commit(types.FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_SUCCESS, reportContent);
    } catch (e) {
      commit(types.FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_FAILURE, e);
      throw e;
    }
  },

  async downloadReport({ commit }, payload) {
    const { instanceToken, interactionType, lang, format, anonymize } = payload;

    commit(types.START_REPORT_LOADING);
    try {
      const response = await reportService.downloadReport(instanceToken, interactionType, lang, format, anonymize);
      commit(types.DOWNLOAD_REPORT_SUCCESS);
      commit(types.COMPLETE_REPORT_LOADING);
      return response;
    } catch (e) {
      commit(types.DOWNLOAD_REPORT_FAILURE, e);
      commit(types.COMPLETE_REPORT_LOADING);
      throw e;
    }
  },

  async downloadRecommendationsReport({ commit }, payload) {
    const { instanceToken, interactionType, lang, format, anonymize } = payload;

    commit(types.START_RECOMMENDATIONS_REPORT_LOADING);
    try {
      const response = await reportService.downloadRecommendationsReport(
        instanceToken,
        interactionType,
        lang,
        format,
        anonymize
      );
      commit(types.DOWNLOAD_RECOMMENDATIONS_REPORT_SUCCESS);
      commit(types.COMPLETE_RECOMMENDATIONS_REPORT_LOADING);
      return response;
    } catch (e) {
      commit(types.DOWNLOAD_RECOMMENDATIONS_REPORT_FAILURE, e);
      commit(types.COMPLETE_RECOMMENDATIONS_REPORT_LOADING);
      throw e;
    }
  },

  async downloadReportByReportToken({ commit }, payload) {
    const { reportDownloadToken, interactionType, lang, format } = payload;

    commit(types.START_REPORT_LOADING, reportDownloadToken);
    try {
      const response = await reportService.downloadReportByReportToken(
        reportDownloadToken,
        interactionType,
        lang,
        format
      );
      commit(types.DOWNLOAD_REPORT_SUCCESS);
      commit(types.COMPLETE_REPORT_LOADING);
      return response;
    } catch (e) {
      commit(types.DOWNLOAD_REPORT_FAILURE, e);
      commit(types.COMPLETE_REPORT_LOADING);
      throw e;
    }
  },

  async getDownloadInfoByReportToken({ commit }, payload) {
    const { reportDownloadToken } = payload;

    try {
      const response = await reportService.getDownloadInfoByReportToken(reportDownloadToken);
      commit(types.FETCH_REPORT_DOWNLOAD_INFO_SUCCESS, response.data);
    } catch (e) {
      commit(types.FETCH_REPORT_DOWNLOAD_INFO_FAILURE, e);
      throw e;
    }
  },
};

export default actions;
