export const state = {
  certificateLoading: null,
  certificateContent: null,
  certificateVerificationContent: null,
  certificateVerificationLoading: null,
  certificateVerificationLink: null,
};

export const mutations = {
  DOWNLOAD_CERTIFICATE_SUCCESS() {},
  DOWNLOAD_CERTIFICATE_FAILURE() {},
  START_CERTIFICATE_LOADING(state) {
    state.certificateLoading = true;
  },
  COMPLETE_CERTIFICATE_LOADING(state) {
    state.certificateLoading = null;
  },
  FETCH_CERTIFICATE_CONTENT_REQUEST() {},
  FETCH_CERTIFICATE_CONTENT_FAILURE() {},
  FETCH_CERTIFICATE_CONTENT_SUCCESS(state, payload) {
    state.certificateContent = payload;
  },
  FETCH_CERTIFICATE_VERIFICATION_SUCCESS(state, payload) {
    state.certificateVerificationContent = payload;
  },
  FETCH_CERTIFICATE_VERIFICATION_FAILURE() {},
  START_CERTIFICATE_VERIFICATION_LOADING(state) {
    state.certificateVerificationLoading = true;
  },
  COMPLETE_CERTIFICATE_VERIFICATION_LOADING(state) {
    state.certificateVerificationLoading = false;
  },
  FETCH_CERTIFICATE_VERIFICATION_LINK_SUCCESS(state, payload) {
    state.certificateVerificationLink = payload;
  },
  FETCH_CERTIFICATE_VERIFICATION_LINK_FAILURE() {},
};
