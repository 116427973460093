export const state = {
  reportLoading: null,
  reportDownloadInfo: null,
  bqpReportContent: null,
  bqaReportContent: null,
  bqaRecommendationsReportContent: null,
  recommendationsReportLoading: null,
};

export const mutations = {
  DOWNLOAD_REPORT_SUCCESS() {},
  DOWNLOAD_REPORT_FAILURE() {},
  START_REPORT_LOADING(state) {
    state.reportLoading = true;
  },
  COMPLETE_REPORT_LOADING(state) {
    state.reportLoading = null;
  },
  FETCH_REPORT_DOWNLOAD_INFO_SUCCESS(state, payload) {
    state.reportDownloadInfo = payload;
  },
  FETCH_BQP_REPORT_CONTENT_REQUEST() {},
  FETCH_BQP_REPORT_CONTENT_SUCCESS(state, payload) {
    state.bqpReportContent = payload;
  },
  FETCH_BQP_REPORT_CONTENT_FAILURE() {},
  FETCH_BQA_REPORT_CONTENT_REQUEST() {},
  FETCH_BQA_REPORT_CONTENT_SUCCESS(state, payload) {
    state.bqaReportContent = payload;
  },
  FETCH_BQA_REPORT_CONTENT_FAILURE() {},
  FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_REQUEST() {},
  FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_SUCCESS(state, payload) {
    state.bqaRecommendationsReportContent = payload;
  },
  FETCH_BQA_RECOMMENDATIONS_REPORT_CONTENT_FAILURE() {},
  DOWNLOAD_RECOMMENDATIONS_REPORT_SUCCESS() {},
  DOWNLOAD_RECOMMENDATIONS_REPORT_FAILURE() {},
  START_RECOMMENDATIONS_REPORT_LOADING(state) {
    state.recommendationsReportLoading = true;
  },
  COMPLETE_RECOMMENDATIONS_REPORT_LOADING(state) {
    state.recommendationsReportLoading = null;
  },
};
