export const BQEnvironmentContextPlugin = {
  install(Vue) {
    /* eslint-disable */
    let env = null;
    if (typeof GLOBAL_ENV_CONFIG === 'undefined') {
      console.warn('GLOBAL_ENV_CONFIG not defined.');
    } else {
      env = (GLOBAL_ENV_CONFIG && GLOBAL_ENV_CONFIG.ENV) || window.location.host;
    }
    Vue.prototype.$env = env;
    /* eslint-enable */
  },
};
