import { validatePermission } from '@/utils/permission';

export const BQUserPermissionPlugin = {
  install(Vue) {
    Vue.prototype.$hasPermission = function (realm, resource, operation) {
      if (this.$store.getters.user && this.$store.getters.user.permissions) {
        return validatePermission(this.$store.getters.user.permissions, realm, resource, operation);
      }
      console.error('No user object found');
      return false;
    };
  },
};
