import { initials } from '@/utils/i18n';

export default {
  clientCompareList: (state) => state.clientCompareList,
  teams: (state) =>
    state.teams.map((team) => ({
      ...team,
      members: team.members.map((member) => {
        return {
          ...member,
          fullName: `${member.name}, ${member.firstName}`,
          initials: initials(member.firstName, member.name),
        };
      }),
    })),
  teamResults: (state) => {
    state.teamResults.team.members = state.teamResults.team.members.map((member) => ({
      ...member,
      initials: initials(member.firstName, member.name),
    }));

    return state.teamResults;
  },
  teamComparisonResults: (state) => state.teamComparisonResults,
};
