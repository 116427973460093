import { LOCALES } from '@/constants/i18n';
import { timeFormatLocales } from '@/constants/date';
import { timeFormatDefaultLocale } from 'd3';
/**
 * maps translation response from API to message object for i18n plugin
 * @param {Array[Object]} translations
 */
export const mapToI18nObject = (translations) => {
  return Object.assign(
    ...translations.map((item) => {
      return { [item.key]: item.text };
    })
  );
};

/**
 * returns EN_US as default locale
 * @param {String} localeId
 */
export const sanitizeLocale = (localeId) => {
  const allowedLanguages = Object.values(LOCALES).map((locale) => locale.id);
  if (!localeId || allowedLanguages.indexOf(localeId.toUpperCase()) === -1) {
    return LOCALES.EN_US.id;
  }
  return localeId.toUpperCase();
};

/**
 * optimistic conversation from any locale format en-US or EN_US to only return the language
 *
 * @param language can be a locale string or 2 letter language
 * @returns {string|*} language part of the given string
 */
export const sanitizeLanguage = (language) => {
  if (language.length > 2) {
    return language.substr(0, 2);
  }
  return language;
};

/**
 * returns name possessive form
 * @param {String} name
 * @param {String} locale
 */
export const possessiveName = (name, locale) => {
  switch (locale) {
    case LOCALES.DE_DE.id:
      return name.match(/[sßxz]$/i) ? `${name}'` : `${name}s`;
    default:
      return name.match(/[sxz]$/i) ? `${name}'` : `${name}'s`;
  }
};

/**
 * returns initials of two names
 * @param {String} firstName
 * @param {String} lastName
 */
export const initials = (firstName, lastName) => {
  return `${firstName.trim().slice(0, 1)}${lastName.trim().slice(0, 1)}`;
};

export const updateD3Locale = (locale) => {
  timeFormatDefaultLocale(timeFormatLocales[locale] || timeFormatLocales.EN_US);
};

export const titleCase = (string) => string[0].toUpperCase() + string.substr(1).toLowerCase();

export const toLocaleCurrencyFormat = (locale, number) =>
  new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
