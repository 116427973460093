import * as API from '@/services/api';

export async function fetchAccountInteractions() {
  const response = await API.get('/api/q');
  return response.data;
}

export async function fetchInteractionLimits(initiatorId, interactionId) {
  const response = await API.get(
    `/api/admin/user/${initiatorId}/resource_limit/BQ_INTERACTION_COUNT/${interactionId}/available`
  );
  return response.data;
}

export async function fetchCreateUserLimits(initiatorId) {
  const response = await API.get(`/api/admin/user/${initiatorId}/resource_limit/BQ_USER_COUNT/BQ_USER_COUNT/available`);
  return response.data;
}

export async function fetchInteractionInstancesByProduct(interactionId) {
  const response = await API.get(`/api/qi?interactionId=${interactionId}`);
  return response.data;
}

export async function fetchInteractionInstanceDashboardResults(interactionInstanceToken) {
  const response = await API.get(`/api/dashboard/${interactionInstanceToken}`);
  return response.data;
}

export async function fetchInteractionInstanceDimensions(interactionInstanceToken) {
  const response = await API.get(`/api/interaction/dimensions/${interactionInstanceToken}`);
  return response.data;
}

export async function fetchInteractionInstanceDimensionsGraph(interactionInstanceToken) {
  const response = await API.get(`/api/graph/${interactionInstanceToken}`);
  return response.data;
}

export async function deleteInteractionInstance(token) {
  const response = await API.del(`/api/interaction/instance/${token}`);
  return response.data;
}

export async function exportUserRepeatedMeasurementInteractions(repeatedMeasurementId) {
  return API.get(`/api/v1/export/user/repeated_measurement/${repeatedMeasurementId}/csv`);
}

export async function createInteractionInstanceForRepeatedMeasurement(repeatedMeasurementId, userToken) {
  const response = await API.post(`/api/admin/repeated_measurement/${repeatedMeasurementId}/interaction`, {
    userToken,
  });
  return response.data;
}

export async function fetchInstanceSimilarity(instanceToken1, instanceToken2) {
  const response = await API.get(
    `/api/comp/BQS_COMP?instanceToken1=${instanceToken1}&instanceToken2=${instanceToken2}`
  ).catch(() => {
    // return failure object which indicates that the API was not able to return the expected result
    // can happen when not all values for the comparison calculation have normalized results
    return {
      data: -1,
    };
  });
  return response.data;
}

export async function fetchBQPPredictions(interactionInstanceToken) {
  const response = await API.get(`/api/predict/bqp/${interactionInstanceToken}`);
  return response.data;
}

export async function fetchBQAPredictions(interactionInstanceToken) {
  const response = await API.get(`/api/predict/bqa/${interactionInstanceToken}`);
  return response.data;
}

// NOTE: provides only Risk Dashboard (if permission is available) and metadata at the moment
// TODO: implement all sections on this endpoint
export async function fetchBQADashboardContent(interactionInstanceToken, locale) {
  const response = await API.get(`/api/dashboard/content/bqa/${interactionInstanceToken}?locale=${locale}`);
  return response.data;
}

// NOTE: provides only
export async function fetchBQPDashboardContent(interactionInstanceToken, locale) {
  const response = await API.get(`/api/dashboard/content/bqp/${interactionInstanceToken}?locale=${locale}`);
  return response.data;
}

export async function fetchSelfAssessmentDashboardContent(interactionToken, locale) {
  const response = await API.get(`/api/dashboard/content/sa/${interactionToken}?locale=${locale}`);
  return response.data;
}
