import * as demoService from '@/services/demo';

const actions = {
  async setDemoUserPassword(_, payload) {
    const { instanceToken, password } = payload;
    await demoService.setDemoUserPassword(instanceToken, password);
  },
};

export default actions;
