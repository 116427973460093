export const FETCH_BI_CATEGORIES_FAILURE = 'FETCH_BI_CATEGORIES_FAILURE';
export const FETCH_BI_CATEGORIES_SUCCESS = 'FETCH_BI_CATEGORIES_SUCCESS';
export const FETCH_BI_CATEGORY_DETAILS_SUCCESS = 'FETCH_BI_CATEGORY_DETAILS_SUCCESS';
export const FETCH_BI_CATEGORY_DETAILS_FAILURE = 'FETCH_BI_CATEGORY_DETAILS_FAILURE';
export const FETCH_BI_CLIENTS_LIST_SUCCESS = 'FETCH_BI_CLIENTS_LIST_SUCCESS';
export const FETCH_BI_CLIENTS_LIST_FAILURE = 'FETCH_BI_CLIENTS_LIST_FAILURE';
export const FETCH_CLIENT_PROFILE_SUCCESS = 'FETCH_CLIENT_PROFILE_SUCCESS';
export const FETCH_CLIENT_PROFILE_FAILURE = 'FETCH_CLIENT_PROFILE_FAILURE';
export const RESET_CLIENT_PROFILE = 'RESET_CLIENT_PROFILE';
export const SET_BI_CATEGORY = 'SET_BI_CATEGORY';
export const SET_CLIENT_GROUP = 'SET_CLIENT_GROUP';
export const START_LOADING_CATEGORY = 'START_LOADING_CATEGORY';
export const COMPLETE_LOADING_CATEGORY = 'COMPLETE_LOADING_CATEGORY';
export const START_LOADING_BI_LIST = 'START_LOADING_BI_LIST';
export const COMPLETE_LOADING_BI_LIST = 'COMPLETE_LOADING_BI_LIST';
