import { PRODUCT } from '@/constants/interaction';
import { initials } from '@/utils/i18n';

function getAvailableProducts(state) {
  if (state.accountInteractions) {
    return state.accountInteractions
      .map((interaction) => PRODUCT.getProductInteractionMapping(interaction.name))
      .filter((product) => product.name !== 'undefined.product');
  }
  return [];
}

export default {
  instanceDashboardResults: (state) => state.instanceDashboardResults && state.instanceDashboardResults.dashboard,
  instanceDashboardResultsUser: (state) =>
    state.instanceDashboardResults && {
      ...state.instanceDashboardResults.user,
      initials: initials(state.instanceDashboardResults.user.firstName, state.instanceDashboardResults.user.name),
    },
  instanceDashboardResultsUserName: (state) =>
    state.instanceDashboardResults &&
    `${state.instanceDashboardResults.user.firstName} ${state.instanceDashboardResults.user.name}`,
  instanceDimensions: (state) => {
    return state.instanceDimensions;
  },
  instanceDimensionsActiveWeights: (state) => state.instanceDimensionsActiveWeights,
  interactionName: (state) => state.interactionName,
  accountInteractions: (state) => state.accountInteractions,
  interactionLimits: (state) => state.interactionLimits,
  productInstances: (state) => state.productInstances,
  selectedInteractionInstance: (state) => state.selectedInteractionInstance,
  availableProducts: (state) => getAvailableProducts(state),
  instanceRiskDashboardResults: (state) =>
    state.bqaDashboardContent && state.bqaDashboardContent.sections && state.bqaDashboardContent.sections.BQA_RISK,
  bqaDashboardContent: (state) => state.bqaDashboardContent,
  bqpDashboardContent: (state) => state.bqpDashboardContent,
  selfAssessmentDashboardContent: (state) => state.selfAssessmentDashboardContent,
};
