import { START_LOADING, COMPLETE_LOADING } from '@/store';
import * as types from './constants';
import * as clientService from '@/services/clients';

const actions = {
  setSelectedBiCategory({ commit }, payload) {
    commit(types.SET_BI_CATEGORY, payload);
  },
  setSelectedClientGroup({ commit }, payload) {
    commit(types.SET_CLIENT_GROUP, payload);
  },
  async fetchBiCategories({ commit }, payload) {
    const { productType, localeId } = payload;

    try {
      const biCategories = await clientService.fetchBiCategories(productType, localeId);
      commit(types.FETCH_BI_CATEGORIES_SUCCESS, biCategories);
    } catch (e) {
      commit(types.FETCH_BI_CATEGORIES_FAILURE, e);
    }
  },
  async fetchBiCategoryDetails({ commit }, payload) {
    const { productType, categoryKey, group, localeId } = payload;
    commit(types.START_LOADING_CATEGORY);

    try {
      const biCategoryDetails = await clientService.fetchBiCategoryDetails(productType, categoryKey, group, localeId);
      commit(types.FETCH_BI_CATEGORY_DETAILS_SUCCESS, biCategoryDetails);
    } catch (e) {
      commit(types.FETCH_BI_CATEGORY_DETAILS_FAILURE, e);
    } finally {
      commit(types.COMPLETE_LOADING_CATEGORY);
    }
  },
  async fetchBiClientsList({ commit }, payload) {
    const { productType, categoryKey, filter, q, group, paginationParams, localeId } = payload;
    commit(types.START_LOADING_BI_LIST);

    try {
      const biClientsList = await clientService.fetchBiClientsList(
        productType,
        categoryKey,
        filter,
        q,
        group,
        paginationParams,
        localeId
      );
      commit(types.FETCH_BI_CLIENTS_LIST_SUCCESS, biClientsList);
      commit(types.COMPLETE_LOADING_BI_LIST);
    } catch (e) {
      commit(types.FETCH_BI_CLIENTS_LIST_FAILURE, e);
      commit(types.COMPLETE_LOADING_BI_LIST);
    }
  },
  /**
   * sets client profile to store
   */
  async fetchClientProfile({ commit }, payload) {
    commit(START_LOADING);

    const { token, productConfigurationId } = payload;

    try {
      const clientProfile = await clientService.fetchClientProfile(token, productConfigurationId);
      commit(types.FETCH_CLIENT_PROFILE_SUCCESS, clientProfile);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_CLIENT_PROFILE_FAILURE, e);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },
  /**
   * returns client info to calling component
   */
  async fetchClientInfo({ commit }, payload) {
    commit(START_LOADING);

    const { token, productConfigurationId } = payload;

    try {
      const clientProfile = await clientService.fetchClientProfile(token, productConfigurationId);
      commit(COMPLETE_LOADING);
      return clientProfile;
    } catch (e) {
      commit(COMPLETE_LOADING);
    }
  },
  resetClientProfile({ commit }) {
    commit(types.RESET_CLIENT_PROFILE);
  },
  async fetchParticipantsForRepeatedMeasurement(_, payload) {
    const { repeatedMeasurementId, showOnlyCompleted } = payload;

    const participants = await clientService.fetchParticipantsForRepeatedMeasurement(
      repeatedMeasurementId,
      showOnlyCompleted
    );
    return participants;
  },
  async fetchParticipants() {
    const participants = await clientService.fetchParticipants();
    return participants;
  },
  async fetchOwnParticipant(_, payload) {
    const { productType } = payload;
    const participants = await clientService.fetchOwnParticipant(productType);
    return participants;
  },
};

export default actions;
