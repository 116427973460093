<template>
  <div id="app" :key="renderKey">
    <router-view v-if="initialized"></router-view>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { extend } from 'vee-validate';
import { required, min, email } from 'vee-validate/dist/rules';
import { TEXT_TYPES } from '@/constants/i18n';

export default {
  name: 'App',
  data() {
    return {
      renderKey: 0,
      initialized: false,
    };
  },
  computed: {
    ...mapGetters(['locale']),
  },
  watch: {
    '$route.query.locale': {
      async handler(locale) {
        // initialize locale for pages which are not children of MainContainer.vue
        // NOTE: MainContainer.vue will override the locale with the user preferred language
        //       but only in case that there is no locale query parameter passed
        this.setInitLocale(locale);
        await this.loadBaseTranslations();
      },
    },
    locale: {
      async handler(newLocale) {
        // watch for changes on locale and set it on the root i18n context,
        // this is required specifically for the language settings page,
        // to immediately change the language of the whole application
        // without needing to refresh the page
        this.$root.$i18n.locale = newLocale.language;

        await this.loadBaseTranslations();
      },
      deep: true,
    },
  },
  async created() {
    await this.fetchSystemEnabledComponents();
  },
  async mounted() {
    document.addEventListener('keydown', this.globalKeyListener.bind(this));

    let locale = this.$route.query.locale;

    if (navigator.language && /^de\b/.test(navigator.language)) {
      locale = 'DE_DE';
    }

    this.setInitLocale(locale);
    await this.loadBaseTranslations();
    // we need to wait with rendering of children for translations to be loaded
    // otherwise validations are not initialized properly because parent-child mount order
    // is not ensured with this async call here
    this.initialized = true;
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.globalKeyListener);
  },
  methods: {
    ...mapActions([
      'setLocale',
      'fetchTranslations',
      'clearTranslations',
      'reloadTranslations',
      'fetchSystemEnabledComponents',
    ]),
    setInitLocale(locale) {
      if (locale) {
        this.setLocale({ locale });
      } else {
        this.setLocale({ locale: 'EN_US' });
      }
    },
    setValidations() {
      // extend base validations for components that are not children of MainContainer here,
      // because in components it's not ensured that BQUI translations are loaded
      extend('required', {
        ...required,
        message: this.$BQUI.COMMON.REQUIRED_INPUT_VALIDATION_ERROR(),
      });

      extend('email', {
        ...email,
        message: this.$BQUI.COMMON.INPUT_EMAIL_VALIDATION_ERROR(),
      });

      extend('tos-checked', {
        validate(value) {
          return value;
        },
        message: this.$BQUI.COMMON.REQUIRED_INPUT_TOS_NOT_ACCEPTED_ERROR(),
      });

      extend('min', {
        ...min,
        message: this.$BQUI.COMMON.MINIMUM_LENGTH_VALIDATION_ERROR(),
      });
    },
    async loadBaseTranslations() {
      try {
        await this.fetchTranslations({ textType: TEXT_TYPES.BQUI });
        await this.fetchTranslations({ textType: TEXT_TYPES.ERROR });
        this.setValidations();
      } catch (e) {
        console.error('Failed to load base translations: ', e);
      }
    },
    async globalKeyListener(e) {
      if (e.metaKey && e.ctrlKey && e.altKey && e.key === 'c') {
        console.log('Clearing translations');
        // clear all text for currently selected locale
        await this.clearTranslations();

        // force page re-render with new translations
        this.renderKey += 1;
      }
      if (e.metaKey && e.ctrlKey && e.altKey && e.key === 'e') {
        // clear all text for currently selected locale
        await this.clearTranslations();
        await this.reloadTranslations('EN');
        this.$i18n.fallbackLocale = null;
        this.setLocale({ locale: 'EN_US' });

        // force page re-render with new translations
        this.renderKey += 1;
      }
      if (e.metaKey && e.ctrlKey && e.altKey && e.key === 'g') {
        // clear all text for currently selected locale
        await this.clearTranslations();
        await this.reloadTranslations('DE');
        this.$i18n.fallbackLocale = null;
        this.setLocale({ locale: 'DE_DE' });

        // force page re-render with new translations
        this.renderKey += 1;
      }
    },
  },
};
</script>
<style lang="scss">
body,
html {
  min-height: 100vh;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-font-grey;
  min-height: 100vh;
  width: 100%;
}
</style>
