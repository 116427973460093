export const state = {
  user: null,
  error: null,
  clientLoading: 0,
  createUserLimits: [],
  currentProduct: null,
  privacyPolicyData: null,
  redirectUrl: null,
  socialsAuthLoading: false,
};

export const mutations = {
  LOGIN_SUCCESS() {},
  LOGIN_FAILURE(state, payload) {
    state.error = payload;
  },
  SOCIALS_AUTH_RESPONSE(state, payload) {
    state.redirectUrl = payload;
  },
  SOCIALS_AUTH_LOADING_START(state) {
    state.socialsAuthLoading = true;
  },
  SOCIALS_AUTH_LOADING_COMPLETE(state) {
    state.socialsAuthLoading = false;
  },
  LOGOUT_SUCCESS(state) {
    state.user = null;
  },
  LOGOUT_FAILURE(state, payload) {
    state.error = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  CREATE_CLIENT_SUCCESS() {},
  CREATE_CLIENT_FAILURE(state, payload) {
    state.error = payload;
  },
  DELETE_CLIENT_SUCCESS() {},
  DELETE_CLIENT_FAILURE(state, payload) {
    state.error = payload;
  },
  START_CLIENT_LOADING(state) {
    state.clientLoading = state.clientLoading + 1;
  },
  UPDATE_CLIENT_SUCCESS(state) {
    state.clientLoading = state.clientLoading - 1;
  },
  UPDATE_CLIENT_FAILURE(state) {
    state.clientLoading = state.clientLoading - 1;
  },
  FETCH_CREATE_USER_LIMITS_SUCCESS(state, payload) {
    state.createUserLimits = payload;
  },
  FETCH_CREATE_USER_LIMITS_FAILURE() {},
  SET_CURRENT_PRODUCT(state, payload) {
    state.currentProduct = payload;
  },
  FETCH_PRIVACY_POLICY_DATA_SUCCESS(state, payload) {
    state.privacyPolicyData = payload;
  },
  FETCH_PRIVACY_POLICY_DATA_FAILURE() {},
};
