import * as certificateService from '@/services/certificate';
import * as types from './constants';

const actions = {
  async fetchBQPCertificateContent({ commit }, payload) {
    const { interactionInstanceToken, locale } = payload;

    commit(types.FETCH_CERTIFICATE_CONTENT_REQUEST);
    try {
      const certificateContent = await certificateService.fetchBQPCertificateContent(interactionInstanceToken, locale);
      commit(types.FETCH_CERTIFICATE_CONTENT_SUCCESS, certificateContent);
    } catch (e) {
      commit(types.FETCH_CERTIFICATE_CONTENT_FAILURE, e);
      throw e;
    }
  },

  async downloadBQPCertificate({ commit }, payload) {
    const { instanceToken, interactionType, lang, format, anonymize } = payload;
    commit(types.START_CERTIFICATE_LOADING);
    try {
      const response = await certificateService.downloadBQPCertificate(
        instanceToken,
        interactionType,
        lang,
        format,
        anonymize
      );
      commit(types.DOWNLOAD_CERTIFICATE_SUCCESS);
      commit(types.COMPLETE_CERTIFICATE_LOADING);
      return response;
    } catch (e) {
      commit(types.DOWNLOAD_CERTIFICATE_FAILURE, e);
      commit(types.COMPLETE_CERTIFICATE_LOADING);
      throw e;
    }
  },

  async fetchBQPCertificateVerification({ commit }, payload) {
    const { certificateVerificationToken, locale } = payload;

    commit(types.START_CERTIFICATE_VERIFICATION_LOADING);
    try {
      const certificateVerification = await certificateService.fetchBQPCertificateVerification(
        certificateVerificationToken,
        locale
      );
      commit(types.FETCH_CERTIFICATE_VERIFICATION_SUCCESS, certificateVerification);
    } catch (e) {
      commit(types.FETCH_CERTIFICATE_VERIFICATION_FAILURE, e);
      throw e;
    } finally {
      commit(types.COMPLETE_CERTIFICATE_VERIFICATION_LOADING);
    }
  },
  async fetchBQPCertificateVerificationLink({ commit }, payload) {
    const { interactionInstanceToken } = payload;

    try {
      const certificateVerificationLink = await certificateService.fetchBQPCertificateVerificationLink(
        interactionInstanceToken
      );
      commit(types.FETCH_CERTIFICATE_VERIFICATION_LINK_SUCCESS, certificateVerificationLink);
    } catch (e) {
      commit(types.FETCH_CERTIFICATE_VERIFICATION_LINK_FAILURE, e);
      throw e;
    }
  },
};

export default actions;
