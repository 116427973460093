export const state = {
  accountInteractions: null,
  interactionLimits: null,
  productInstances: null,
  selectedInteractionInstance: null,
  instanceDashboardResults: null,
  instanceDimensions: null,
  instanceDimensionsActiveWeights: null,
  interactionName: null,
  resultsUser: null,
  bqaDashboardContent: null,
  bqpDashboardContent: null,
  selfAssessmentDashboardContent: null,
};

export const mutations = {
  FETCH_INTERACTIONS_SUCCESS(state, payload) {
    state.accountInteractions = payload;
  },
  FETCH_INTERACTIONS_FAILURE() {},
  FETCH_INTERACTION_LIMITS_SUCCESS(state, payload) {
    state.interactionLimits = payload;
  },
  FETCH_INTERACTION_LIMITS_FAILUE() {},
  FETCH_PRODUCT_INSTANCES_SUCCESS(state, payload) {
    state.productInstances = payload;
  },
  FETCH_PRODUCT_INSTANCES_FAILURE() {},
  FETCH_INSTANCE_DASHBOARD_RESULTS_REQUEST(state) {
    state.instanceDashboardResults = null;
  },
  FETCH_INSTANCE_DASHBOARD_RESULTS_SUCCESS(state, payload) {
    state.instanceDashboardResults = payload;
  },
  FETCH_INSTANCE_DASHBOARD_RESULTS_FAILURE() {},
  FETCH_INSTANCE_DIM_RESULTS_REQUEST(state) {
    state.instanceDimensions = null;
    state.interactionName = null;
  },
  FETCH_INSTANCE_DIM_RESULTS_SUCCESS(state, payload) {
    state.instanceDimensions = payload.dimensions;
    state.interactionName = payload.interactionName;

    if (payload.weights) {
      state.instanceDimensionsActiveWeights = payload.weights;
    } else {
      state.instanceDimensionsActiveWeights = null;
    }
  },
  FETCH_INSTANCE_DIM_RESULTS_FAILURE() {},
  SET_SELECTED_INSTANCE(state, payload) {
    state.selectedInteractionInstance = payload;
  },
  CREATE_INSTANCE_SUCCESS() {},
  CREATE_INSTANCE_FAILURE() {},
  DELETE_INSTANCE_SUCCESS() {},
  DELETE_INSTANCE_FAILURE() {},
  FETCH_BQA_DASHBOARD_CONTENT_REQUEST(state) {
    state.bqaDashboardContent = null;
  },
  FETCH_BQA_DASHBOARD_CONTENT_SUCCESS(state, payload) {
    state.bqaDashboardContent = payload;
  },
  FETCH_BQA_DASHBOARD_CONTENT_FAILURE() {},
  FETCH_BQP_DASHBOARD_CONTENT_REQUEST(state) {
    state.bqpDashboardContent = null;
    state.instanceDimensionsActiveWeights = null;
  },
  FETCH_BQP_DASHBOARD_CONTENT_SUCCESS(state, payload) {
    state.bqpDashboardContent = payload;
    if (payload.weights) {
      state.instanceDimensionsActiveWeights = payload.weights;
    } else {
      state.instanceDimensionsActiveWeights = null;
    }
  },
  FETCH_BQP_DASHBOARD_CONTENT_FAILURE() {},
  FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_REQUEST(state) {
    state.selfAssessmentDashboardContent = null;
  },
  FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_SUCCESS(state, payload) {
    state.selfAssessmentDashboardContent = payload;
  },
  FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_FAILURE() {},
};
