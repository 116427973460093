import { START_LOADING, COMPLETE_LOADING } from '@/store';

import * as interactionService from '@/services/interaction';
import * as emailService from '@/services/email';
import * as types from './constants';

const actions = {
  async fetchAccountInteractions({ commit }) {
    commit(START_LOADING);

    try {
      const interactions = await interactionService.fetchAccountInteractions();
      commit(types.FETCH_INTERACTIONS_SUCCESS, interactions);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_INTERACTIONS_FAILURE, e);
      commit(COMPLETE_LOADING);
    }
  },

  async fetchInteractionLimits({ commit }, payload) {
    commit(START_LOADING);
    const { initiatorId, interactionId } = payload;
    try {
      const interactionLimits = await interactionService.fetchInteractionLimits(initiatorId, interactionId);
      commit(types.FETCH_INTERACTION_LIMITS_SUCCESS, interactionLimits.limits);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_INTERACTION_LIMITS_FAILURE, e);
      commit(COMPLETE_LOADING);
    }
  },

  async fetchInteractionInstancesByProduct({ commit }, payload) {
    commit(START_LOADING);
    const { interactionId } = payload;
    try {
      const instances = await interactionService.fetchInteractionInstancesByProduct(interactionId);
      commit(types.FETCH_PRODUCT_INSTANCES_SUCCESS, instances);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_PRODUCT_INSTANCES_FAILURE);
      commit(COMPLETE_LOADING);
    }
  },

  async fetchInteractionInstanceDashboardResults({ commit }, payload) {
    commit(START_LOADING);
    commit(types.FETCH_INSTANCE_DASHBOARD_RESULTS_REQUEST);
    const { interactionInstanceToken } = payload;
    try {
      const results = await interactionService.fetchInteractionInstanceDashboardResults(interactionInstanceToken);
      commit(types.FETCH_INSTANCE_DASHBOARD_RESULTS_SUCCESS, results);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_INSTANCE_DASHBOARD_RESULTS_FAILURE);
      commit(COMPLETE_LOADING);
    }
  },

  async fetchInteractionInstanceDimensions({ commit }, payload) {
    commit(START_LOADING);
    commit(types.FETCH_INSTANCE_DIM_RESULTS_REQUEST);
    const { interactionInstanceToken } = payload;
    try {
      const results = await interactionService.fetchInteractionInstanceDimensions(interactionInstanceToken);
      commit(types.FETCH_INSTANCE_DIM_RESULTS_SUCCESS, results);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_INSTANCE_DIM_RESULTS_FAILURE);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  async deleteInteractionInstance({ commit }, payload) {
    commit(START_LOADING);
    const { token } = payload;

    try {
      await interactionService.deleteInteractionInstance(token);
      commit(types.DELETE_INSTANCE_SUCCESS);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.DELETE_INSTANCE_FAILURE);
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  setInteractionInstance({ commit }, payload) {
    const { instance } = payload;
    commit(types.SET_SELECTED_INSTANCE, instance);
  },

  async exportUserRepeatedMeasurementInteractions({ commit }, payload) {
    commit(START_LOADING);
    const { repeatedMeasurementId } = payload;
    try {
      const result = await interactionService.exportUserRepeatedMeasurementInteractions(repeatedMeasurementId);
      commit(COMPLETE_LOADING);
      return result;
    } catch (e) {
      commit(COMPLETE_LOADING);
    }
  },

  async createInteractionInstanceForRepeatedMeasurement({ commit }, payload) {
    commit(START_LOADING);
    const { repeatedMeasurementId, userToken } = payload;

    try {
      const instance = await interactionService.createInteractionInstanceForRepeatedMeasurement(
        repeatedMeasurementId,
        userToken
      );
      commit(types.CREATE_INSTANCE_SUCCESS);
      commit(COMPLETE_LOADING);
      return instance;
    } catch (e) {
      commit(types.CREATE_INSTANCE_FAILURE);
      commit(COMPLETE_LOADING);
    }
  },

  // eslint-disable-next-line no-unused-vars
  async fetchBQPPredictionsForInteraction({ commit }, payload) {
    const { interactionInstanceToken } = payload;

    const instance = await interactionService.fetchBQPPredictions(interactionInstanceToken);
    return instance;
  },

  // eslint-disable-next-line no-unused-vars
  async fetchBQAPredictionsForInteraction({ commit }, payload) {
    commit(START_LOADING);
    const { interactionInstanceToken } = payload;

    try {
      const instance = await interactionService.fetchBQAPredictions(interactionInstanceToken);
      commit(COMPLETE_LOADING);
      return instance;
    } catch (e) {
      commit(COMPLETE_LOADING);
      throw e;
    }
  },

  // eslint-disable-next-line no-unused-vars
  async sendInteractionInvitationEmail(_, payload) {
    await emailService.sendInteractionInvitationEmail(payload);
  },
  // eslint-disable-next-line no-unused-vars
  async updateInteractionAutosends(_, payload) {
    const { interactionInstanceToken, autoSendReport, autoReminder } = payload;
    await emailService.updateInteractionAutosends(interactionInstanceToken, { autoSendReport, autoReminder });
  },

  async fetchBQADashboardContent({ commit }, payload) {
    commit(START_LOADING);
    commit(types.FETCH_BQA_DASHBOARD_CONTENT_REQUEST);
    const { interactionInstanceToken, locale } = payload;
    try {
      const results = await interactionService.fetchBQADashboardContent(interactionInstanceToken, locale);
      commit(types.FETCH_BQA_DASHBOARD_CONTENT_SUCCESS, results);
    } catch (e) {
      commit(types.FETCH_BQA_DASHBOARD_CONTENT_FAILURE);
    } finally {
      commit(COMPLETE_LOADING);
    }
  },

  async fetchBQPDashboardContent({ commit }, payload) {
    commit(START_LOADING);
    commit(types.FETCH_BQP_DASHBOARD_CONTENT_REQUEST);
    const { interactionInstanceToken, locale } = payload;
    try {
      const results = await interactionService.fetchBQPDashboardContent(interactionInstanceToken, locale);
      commit(types.FETCH_BQP_DASHBOARD_CONTENT_SUCCESS, results);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_BQP_DASHBOARD_CONTENT_FAILURE);
      commit(COMPLETE_LOADING);
    }
  },

  async fetchSelfAssessmentDashboardContent({ commit }, payload) {
    commit(START_LOADING);
    commit(types.FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_REQUEST);
    const { interactionInstanceToken, locale } = payload;
    try {
      const results = await interactionService.fetchSelfAssessmentDashboardContent(interactionInstanceToken, locale);
      commit(types.FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_SUCCESS, results);
      commit(COMPLETE_LOADING);
    } catch (e) {
      commit(types.FETCH_SELF_ASSESSMENT_DASHBOARD_CONTENT_FAILURE);
      commit(COMPLETE_LOADING);
    }
  },
};

export default actions;
