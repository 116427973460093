import moment from 'moment';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    w: '1 week',
    ww: '%d weeks',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

export const setMomentLocale = (localeString) => {
  moment.locale(localeString);
};

export const formatDateTime = (dateTime, localeFormat) => {
  if (dateTime === undefined) return '';

  const date = moment(dateTime);
  return date.isValid() ? date.format(localeFormat) : '';
};

export const expirationDateFormat = (dateTime, localeFormat) => {
  if (dateTime === undefined) return '';

  const date = moment(dateTime);
  date.add(1, 'y');
  date.subtract(1, 'd');
  return date.isValid() ? date.format(localeFormat) : '';
};

export const compareDate = (dateA, dateB) => {
  if (typeof dateA === 'undefined' && typeof dateB !== 'undefined') {
    return -1;
  }

  if (typeof dateA !== 'undefined' && typeof dateB === 'undefined') {
    return 1;
  }

  const a = moment(dateA);
  const b = moment(dateB);

  if (a.isAfter(b)) return 1;
  if (b.isAfter(a)) return -1;
  return 0;
};

export const getQuarter = (date) => {
  return moment(date).quarter();
};

export const getTimeAgo = (date) => {
  return moment(date).fromNow();
};
